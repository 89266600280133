import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout";

const description = `
Rinat Silnov's resume.
`;

const keywords = ['Resume', 'CV', 'Software Engineer', 'React', 'JavaScript', 'Golang', 'Python'];


const Resume = () => (
    <Layout>
        <SEO title="Resume - Rinat Silnov" description={description} keywords={keywords}/>
        <style dangerouslySetInnerHTML={{__html: `
            header, footer, hr.main {
              display: none;
            }
        `}} />
        <section className="resume">
            <div className="top-header">
                <div className="header-row">
                    <b className="resume__name">Rinat Silnov</b>
                    <div>
                        <a href="mailto:mail@rinat.io">mail@rinat.io</a>
                        {/*<a href="mailto:rinat.silnov@gmail.com">rinat.silnov@gmail.com</a>*/}
                    </div>
                </div>
                <div className="header-row">
                    <div>Berlin, Germany 10437</div>
                    {/*<div>*/}
                        {/*+49 160 91713344*/}
                    {/*</div>*/}
                </div>

            </div>
            {/*<h5>*/}
                {/*Summary*/}
            {/*</h5>*/}
            {/*<ul>*/}
                {/*<li>*/}
                    {/*Over 9 years of experience as an engineer and team lead in all phases of*/}
                    {/*the software development lifecycle including initial negotiations*/}
                    {/*with clients and requirements analysis with 2+ years experience*/}
                    {/*in project management.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Solid knowledge in building and delivering software in a variety of*/}
                    {/*business areas and domains including e-commerce, IoT, CRM systems,*/}
                    {/*productivity and B2B applications.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Proven track record in large-scale cloud based and on-premises software*/}
                    {/*design and development with monolith and microservices architecture*/}
                    {/*leveraging SPA and MVC frameworks.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Strong management, planning, architecting, designing and programming capabilities.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Excellent analytical, problem solving, communication and team skills.*/}
                {/*</li>*/}
            {/*</ul>*/}

            <h5>Work Experience</h5>
            <div className="flex job-header">
                <div><b>Engineering Manager / Team Lead</b> <br/><b>Basking Automation GmbH</b></div>
                <div className="dates">07/2018-present<br/></div>
            </div>
            <div className="job-desc"><em>
              E.ON-backed workplace BI platform used by several Fortune 500 companies helping them improve employees’ productivity and capture cost savings.
              Went through a Techstars accelerator which has 1-2% acceptance rate.
            </em></div>
            <ul>
                {/*<li>*/}
                    {/*Leading development and implementation of the BI analytics application*/}
                    {/*that has been bought and installed by several Fortune 500 companies.*/}
                {/*</li>*/}
                <li>
                    Leading development team of four engineers and establishing engineering processes, implementing weekly code sprints, agile methodologies, and code reviews.
                </li>
                <li>
                    Designed and launched a data pipeline and machine learning to power app insights and business recommendations.
                </li>
                <li>
                    Built scalable data ingestion API for hardware vendors to support fast integration and application deployment.
                </li>
                {/*<li>*/}
                  {/*Hired and managed remote development, data science team, data science team, and assistant*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Managing development team and responsible for hiring, mentoring and training new team members.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Architecting and implementing severless and containers focused cloud strategy that allows us to streamline operations and development, increasing scalability and availability and reducing the computing costs.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Driving the design and launch of multiple data extraction, transformation and loading pipelines on top of AWS data lake to enable business intelligence with data visualizations and high impact dashboards translating insights into business recommendations.*/}
                {/*</li>*/}
                <li>
                    Providing technical consulting for partners and customers, presenting security and architecture reviews.
                    {/*Collecting requirements and setting technical directions for the project.*/}
                </li>
            </ul>

            <div className="flex job-header">
                <div><b>Senior Software Engineer / Senior Member of Technical Staff</b> <br/><b>Quantenna Communications Inc.</b></div>
                <div className="dates">02/2016-06/2018<br/></div>
            </div>
            <div className="job-desc"><em>
              {/*The global leader and innovator of high performance Wi-Fi solutions*/}
              {/*with cloud analytics tool designed to speed up time-to-market*/}
              {/*for new in-home solutions and features deployed in ~10M nodes worldwide.*/}
              {/*It was acquired in 2019 for $1B.*/}

              {/*The global leader and innovator of high performance Wi-Fi solutions*/}
              {/*with cloud analytics tool designed to accelerate service provider deployments by using advanced analytics*/}
              {/*in ~10M nodes worldwide.*/}
              {/*It was acquired in 2019 for $1B.*/}

              {/*The global leader and innovator of high performance Wi-Fi solutions*/}
              {/*with cloud analytics tool helping service providers deliver real-time,*/}
              {/*automated network monitoring and optimization. over ~10M nodes deployed worldwide.*/}
              {/*Was acquired for $1B in 2019.*/}

              The global leader of high performance Wi-Fi solutions. Worked on a team building a cloud-based analytics
              tool deployed in over 10 million nodes worldwide. Quantenna was acquired for $1B in 2019.
            </em></div>
            <ul>
                {/*<li>*/}
                    {/*Designed, developed and maintained cloud IoT application to support over a million online devices.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Increased app search speed from ~30s to 200ms*/}
                {/*</li>*/}
                <li>
                    Implemented integration with Kafka and Cassandra to speed up write-heavy endpoints and offload data processing.
                </li>
                <li>
                    {/*Led project to change search engine from MySQL to ElasticSearch that reduced response time from ~30 seconds to 200ms.*/}

                    {/*Reduced app search response time from ~30s to 200ms by setting up and configuring integration with Elasticsearch engine.*/}
                    Activated and maintained Elasticsearch database which helped to reduce app search time from ~30s to 200ms.
                </li>
                {/*<li>*/}
                    {/*Performed migration from traditional database to Kafka and Cassandra to support better scalability.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Interfaced with other teams to gather requirements and improve application.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Implemented API with OAuth2, JWT and Device Flow protocols to support a single authorization server for multiple applications and meet security requirements.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Cassandra for logs*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Enable JWT on front end*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Introduced Alerts*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Add device terminal*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Developed cloud sniffer tool to help field application engineers debug device issues remotely.*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*Assisted, supported and trained new engineering team members and conducted design and code reviews.*/}
                {/*</li>*/}
                <li>
                    Installed and managed app analytics to track user engagement, gain insights, and improve navigation and UX.
                </li>
                {/*<li>*/}
                    {/*Introduced project changelog to bring more visibility into what notable changes have been made between releases.*/}
                {/*</li>*/}
            </ul>

            <div className="no-break">
                <div className="flex job-header">
                    <div><b>Software Engineer / Member of Technical Staff</b> <br/><b>Quantenna Communications Inc.</b></div>
                    <div className="dates">11/2013-01/2016<br/></div>
                </div>
                <ul>
                    {/*<li>*/}
                      {/*Led project to redesign web app presenting wireframes and implementing a user interface.*/}
                        {/*/!*Developed application programming interface (API) and user interface (UI) dashboards.*!/*/}
                    {/*</li>*/}
                    {/*<li>*/}
                      {/*Designed wireframes and determined the visual layout and user experience (UX) of the application.*/}
                    {/*</li>*/}
                    <li>
                        Led project to redesign web app. Implemented report builder, advanced search, and authorization features.
                    </li>
                    <li>
                        Developed map module with node clustering to enable full deployment visualization for millions of devices.
                    </li>
                    <li>
                        Created and launched a Jenkins CI job with end-to-end and unit tests, linting, and assets minification.
                        {/*to help automate developers operations.*/}
                        {/*Created, supported and audited functional, UI and unit tests for front and back end in Jenkins CI.*/}
                    </li>
                    {/*<li>*/}
                        {/*Introduced roles on front end.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Developed back end along with two other engineers using Python, Django, MySQL and Redshift.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Extended API and web search form to support more options and filters for advanced users.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Changed time-series queries to use Redshift for better speed.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*/!*Configured and developed JS build system to optimize web assets for best speed and performance.*!/*/}
                        {/*Identified and resolved performance and scalability issues.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Set up and supported Jenkins CI to automate QA, generate code coverage reports and build*/}
                        {/*documentation.*/}
                    {/*</li>*/}
                </ul>
            </div>

            <div className="no-break">
                <div className="flex job-header">
                    <div><b>Software Engineer</b> <br/><b>Clevertech</b><br/></div>
                    <div className="dates">07/2011-10/2013</div>
                </div>
              <div className="job-desc"><em>IT consultancy firm providing development services and helping
                organizations to launch innovative digital products.</em></div>
                <ul>
                    {/*<li>*/}
                        {/*Developed and maintained front and back end functionality of various projects*/}
                        {/*including customer relations management (CRM) systems,*/}
                        {/*media startup and online financial modeling tool.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Led lean media startup development of */}
                    {/*</li>*/}
                    <li>
                        Designed and built a module enabling real-time collaboration for an online financial modeling tool.
                    </li>
                    <li>
                      Led project to move legacy desktop-based CRM system to the cloud which allowed users to access it from anywhere.
                        {/*Led project to move legacy desktop based CRM system to the cloud. This helped customer to reduce development and maintenance costs and allowed users to access it from anywhere.*/}
                    </li>
                    <li>
                        Built initial prototype / minimum viable product for a "crowdearning" social startup.
                        {/*designed to help artists succeed by allowing fans to invest in their success.*/}
                    </li>
                    {/*<li>*/}
                        {/*Interacted with clients to collect feedback and translate needs into technical requirements.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Integrated applications with third-party API and payment gateways, including Facebook, Twitter,*/}
                        {/*Google,*/}
                        {/*Amazon and PayPal to provide best customer experience.*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*Designed and managed SQL databases to meet client needs and keep good performance and*/}
                        {/*scalability.*/}
                    {/*</li>*/}
                </ul>
            </div>

            <h5>Technical Profile</h5>
            <table className="skills">
                <tr>
                    <td><b>Languages:</b></td>
                    <td>Python, JavaScript, Go, SQL, HTML, CSS</td>
                </tr>
                <tr>
                    <td><b>Frameworks:</b></td>
                    <td>React, Django, Flask, Redux, AngularJS</td>
                </tr>
                <tr>
                    <td><b>Databases:</b></td>
                    <td>PostgreSQL, AWS Athena, MySQL, Cassandra, ElasticSearch, Redshift</td>
                </tr>
                <tr>
                    <td>
                        <b>Technologies:</b>
                    </td>
                    <td>
                        Lambda, Kinesis, Kafka, Airflow, Git, REST, GraphQL, gRPC, OAuth
                        {/*, Node.js*/}
                        {/*, SCSS*/}
                    </td>
                </tr>
                {/*<tr>*/}
                    {/*<td>*/}
                        {/*<b>Software Testing:</b>*/}
                    {/*</td>*/}
                    {/*<td>*/}
                        {/*TDD, BDD, Unit tests, functional and E2E tests, pytest, PyUnit, Karma, Jasmine*/}
                    {/*</td>*/}
                {/*</tr>*/}
                {/*<tr>*/}
                    {/*<td><b>Other Tools:</b></td>*/}
                    {/*<td>Pandas, , SaltStack, Capistrano, Grafana</td>*/}
                {/*</tr>*/}
                <tr>
                    <td><b>Administration:</b></td>
                    <td>AWS, Ubuntu, Nginx, Jenkins, CloudFormation</td>
                </tr>
            </table>

            <h5>Education</h5>
            <div className="flex">
                <div>
                    <b>Master of Engineering and Technology</b><br/>
                    ITMO University — St.Petersburg, Russia.
                </div>
                <div className="dates">
                    2010 - 2012
                </div>
            </div>
            <div className="flex">
                <div>
                    <b>Bachelor of Engineering and Technology</b><br/>
                    ITMO University — St.Petersburg, Russia.
                </div>
                <div className="dates">
                    2006 - 2010
                </div>
            </div>
            <div className="flex edu">
                <div>
                    <b>Computer Science Algorithms</b><br/>
                    Princeton University, Coursera (online course).
                </div>
                <div className="dates">
                    03/2018 - 05/2018
                </div>
            </div>
            {/*<div className="flex edu">*/}
                {/*<div>*/}
                    {/*<b>Software Engineering for Software as a Service</b><br/>*/}
                    {/*UC Berkeley, Coursera (online course)*/}
                {/*</div>*/}
                {/*<div className="dates">*/}
                    {/*02/2012 - 04/2012*/}
                {/*</div>*/}
            {/*</div>*/}
        </section>
    </Layout>
);

export default Resume
